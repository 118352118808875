import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Page from "../components/page";
import Section from "../components/section";
import FullWidthImage from "../components/FullWidthImage";
import NewsItem from "../components/news_item";
import { Title } from "../components/text";

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const { frontmatter: content } = data.markdownRemark;
    console.log(content);
    const { title, image } = content;
    return (
      <Page>
        <Helmet title="Wesmans | News" />
        <FullWidthImage
          image={image}
          height="50vh"
          parallax={50}
          style={{
            backgroundPosition: "bottom",
          }}
        >
          <Title bright>{title}</Title>
        </FullWidthImage>
        <Section diffBg>
          {posts
            .filter((post) => post.node.frontmatter.templateKey === "blog-post")
            .map(({ node: post }) => (
              <NewsItem
                key={post.fields.slug}
                image={post.frontmatter.image}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                excerpt={post.excerpt}
                id={post.fields.slug}
              />
            ))}
        </Section>
      </Page>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
