import React, { Component } from "react";
import { LinkButton } from "./button/button";
import { Columns, Column } from "../components/columns";
import { CardTitle, Italic, Paragraph } from "../components/text";

import "./news_item.sass";

export default class NewsItem extends Component {
  render() {
    const { buttonText, image, title, date, excerpt, id } = this.props;

    return (
      <div className="news-item">
        <div className="news-image-container">
          <div
            className="news-image"
            style={{
              backgroundImage: `url(${image?.childImageSharp?.fluid.src})`,
            }}
          ></div>
        </div>
        <div className="news-item-content">
          <CardTitle center>{title}</CardTitle>
          <Italic center marginTop>
            <small>{date}</small>
          </Italic>
          <Paragraph center marginTop>
            {excerpt}
          </Paragraph>
          <LinkButton
            to={id}
            title={buttonText ? buttonText : "Read more"}
            center
            marginTop
          />
        </div>
      </div>
    );
  }
}
// <img src={ image } alt={ title } />
// <div
//   className="news-image"
//   style={{
//     backgroundImage: `url(${ image })`
//   }}
// >
// </div>
// title="Read more →"
